import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Colors from "@styles/colors"
import Box from "./Box"
import Flex from "./Flex"
import Link from "./Link"
import Heading6 from "./Headings/Heading6"

const Wrapper = styled(Box)`
  list-style: none;
  width: 100%;
`

const BranchTitle = styled(Heading6)`
  color: ${Colors.black};
`

const BranchLink = styled(Link)`
  color: ${Colors.gray.dark};
  text-decoration: none;
  padding: 0.45em 0.5em;
  display: flex;
  width: 100%;
  font-size: 0.9em;

  &:hover {
    text-decoration: none;
    color: ${Colors.gray.darkest};
  }

  &.active {
    background-color: ${Colors.primary.normal};
    color: ${Colors.black};
    border-radius: 4px;

    & .branch-title {
      color: ${Colors.black};
    }
  }
`

const TableOfContents = ({ items = [] }) => (
  <Wrapper as={"ul"}>
    {items.map((branch) => (
      <Flex as="li" key={branch.branchTo} mb={"1rem"} flexDirection={"column"}>
        <BranchLink
          activeClassName={branch.tree.length === 0 ? "active" : ""}
          to={branch.branchTo}
        >
          <BranchTitle className={"branch-title"}>
            {branch.branchTitle}
          </BranchTitle>
        </BranchLink>
        <Wrapper as={"ul"}>
          {branch.tree.map((item) => (
            <Flex as={"li"} key={item.to}>
              <BranchLink
                to={item.to}
                activeClassName={"active"}
                partiallyActive
              >
                {item.title}
              </BranchLink>
            </Flex>
          ))}
        </Wrapper>
      </Flex>
    ))}
  </Wrapper>
)

TableOfContents.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      branchTitle: PropTypes.string.isRequired,
      branchTo: PropTypes.string.isRequired,
      tree: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          to: PropTypes.string.isRequired,
        })
      ),
    })
  ),
}

export default TableOfContents

export const createTableOfContentsBranch = (title, to, tree) => ({
  branchTitle: title,
  branchTo: to,
  tree,
})

export const createTableOfContentsTreeItem = (title, to) => ({
  title,
  to,
})
