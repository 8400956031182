import PropTypes from "prop-types"
import React from "react"
import Documentation from "./Documentation"
import styled from "styled-components"
import Box from "../Box"
import Heading1 from "../Headings/Heading1"
import SEO from "../SEO/seo"

const Page = styled(Box)`
  padding-bottom: 1.5rem;
`

const PageTitle = styled(Heading1)`
  margin-bottom: 1.5rem;
`

const DocumentationPage = ({ title, children }) => (
  <Documentation>
    <SEO title={title} />
    <Page>
      <PageTitle>{title}</PageTitle>
      <Box width={"70%"}>{children}</Box>
    </Page>
  </Documentation>
)

DocumentationPage.propTypes = {
  title: PropTypes.node.isRequired,
}

export default DocumentationPage
