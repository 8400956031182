import React from "react"
import styled from "styled-components"
import Colors from "@styles/colors"
import Flex from "@components/Flex"
import Box from "@components/Box"
import TableOfContents, {
  createTableOfContentsBranch,
  createTableOfContentsTreeItem,
} from "@components/TableOfContents"
import Header from "../Header/Header"
import Footer from "../Footer"
import BaseContainer from "../Container"

const DocumentationWrapper = styled(Flex)`
  max-height: 90vh;
`

const Container = styled(BaseContainer)`
  flex-direction: row;
  display: flex;
  margin: 0 auto;
  height: 100%;
`

const Sidebar = styled(Flex)`
  background-color: #fafafc;
  position: relative;
  min-height: calc(100vh - 80px);
  border-right: 1px solid ${Colors.gray.normal};
  flex-basis: 350px;
  padding: 1.5rem;
  padding-left: 0;
  overflow: scroll;
`

const Main = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding: 1.5rem;
`

const tableOfContentsItems = [
  createTableOfContentsBranch("Home", "/docs", []),
  createTableOfContentsBranch(
    "Getting Started",
    "/docs/getting-started/workflow",
    [
      createTableOfContentsTreeItem(
        "Workflow",
        "/docs/getting-started/workflow"
      ),
      createTableOfContentsTreeItem("Integrations", "/docs/integrations"),
    ]
  ),
  createTableOfContentsBranch(
    "Accounts and organizations",
    "/docs/accounts/membership",
    [
      createTableOfContentsTreeItem(
        "Organizations",
        "/docs/accounts/membership"
      ),
    ]
  ),
]

const Documentation = ({ children }) => (
  <Box>
    <Header />
    <DocumentationWrapper>
      <Container>
        <Sidebar>
          <TableOfContents items={tableOfContentsItems} />
        </Sidebar>
        <Main as={"main"}>
          <Box minHeight={"95%"}>{children}</Box>
          <Footer />
        </Main>
      </Container>
    </DocumentationWrapper>
  </Box>
)

Documentation.propTypes = {}

export default Documentation
