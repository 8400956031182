import React from "react"
import Page from "@components/Layout/DocumentationPage"
import Paragraph from "@components/Paragraph"
import List from "@components/List"
import Link from "@components/Link"
import Heading2 from "@components/Headings/Heading2"

const Docs = () => (
  <Page title={"ChangeHub documentation"}>
    <Paragraph mb={"1rem"}>
      Welcome to the ChangeHub documentation. We provide an automated overview
      of the changes to your product while they are being build by your
      engineering team.
    </Paragraph>
    <Heading2>Getting Started</Heading2>
    <Paragraph>
      To get started with ChangeHub, you might find some of the following links
      relevant:.
    </Paragraph>
    <List>
      <li>
        Read more about <Link to={""}>the ChangeHub workflow</Link>.
      </li>
      <li>
        Don't have an account yet?{" "}
        <Link external to={"https://app.changehub.io/account/signup"}>
          Sign up now
        </Link>
        .
      </li>
    </List>
    <Heading2>Get in touch</Heading2>
    <Paragraph>
      If you have any further questions, remarks or requests please{" "}
      <Link
        to={"mailto:changehub@madewithlove.be?Subject=Security%20question"}
        external
        target={"_blank"}
        rel={"noopener noreferrer"}
      >
        reach out to us
      </Link>
      .
    </Paragraph>
  </Page>
)

export default Docs
